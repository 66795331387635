<template>
  <b-row :class="{ 'mt-5 p-2' : $mq === 'sm' || $mq === 'xs' || $mq === 'md'}" align="center">
      <div class="loading" v-if="showLoading">
        <b-spinner style="width: 5rem; height: 5rem; margin: auto;" variant="primary"></b-spinner>
      </div>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <title-page title="BLOG" class="titulo"/>
      </b-col>
      <b-col cols="12" sm="12" md="6" lg="3" xl="3" v-for="(post, index) in posts" :key="index">
          <template>
            <card-new 
              v-if="posts.length > 0"
              :id="post.id"
              :slug="post.slug"
              :img="post.foto" 
              :legend="post.titulo"
              />
          </template>
      </b-col>
  </b-row>
</template>

<script>
import TitlePage from '../components/TitlePage.vue'
import CardNew from '../components/CardNew.vue'
import { collection, getDocs } from "firebase/firestore"

export default {
  name: 'blog',
  components:{
      TitlePage,
      CardNew
  },
  data(){
    return{
      posts: null,
      showLoading: false,
    }
  },
  computed:{
    imgWebp(){
      return this.canUseWebP()
    }
  },
  methods: {
    canUseWebP() {
      var elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      return false;
    },
    dynamicSort(property) {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    },
    async inicializar(){
      this.showLoading = true;
      if(!this.$store.getters.getAllPosts){
        let collectionRef = collection(this.$db, "Blog");
        let posts = await getDocs(collectionRef)
        this.posts = posts.docs.map(item=> item.data()).sort(this.dynamicSort("-id"))
        this.$store.dispatch('setAllPosts', this.posts)
      } else {
        this.posts = this.$store.getters.getAllPosts
      }
      this.showLoading = false;
    }
  },
  created(){
    this.inicializar();
    window.scrollTo({top: 0, behavior: 'smooth'});
    document.body.style.backgroundColor = "#0D245E"
    document.body.style.backgroundImage = this.$mq !== 'xs' 
                                          && this.$mq !== 'sm' 
                                          && this.$mq !== 'md' ? 
                                            !this.imgWebp ? "url('/img/BG_BLOG.jpg')" : "url('/img/BG_BLOG.webp')" : 
                                            !this.imgWebp ? "url('/img/sm/BG_BLOG.jpg')" : "url('/img/sm/BG_BLOG.webp')"
  }
}
</script>

<style scoped>
.content{
  display: flex;
  flex-wrap: wrap;
  width: 900px;
  margin: auto;
  justify-content: space-between;
}
.titulo{
  margin-bottom: 60px !important;
}
.loading{
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>